import { Link } from "gatsby";
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import HamburgerMenu from "./HamburgerMenu";
import urls from "./urls";
import useDarkMode from "use-dark-mode";
import { AiOutlineEye, AiFillEye } from "react-icons/ai";
import { TbBrandDiscord, TbBrandDiscordFilled } from "react-icons/tb";
import adminEmails from "../util/adminEmails";
import { UserContext } from "./Layout";

const Header = styled.header`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  color: var(--color);
  z-index: 10001;

  .inner-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin: 0;
      padding: 0;
      a {
        color: var(--color);
        text-decoration: none;

        &.active {
          text-decoration: underline;
        }

        /* .full {
          display: block;
        }

        .short {
          display: none;
        } */
        // Above is what should be there

        .short {
          display: block;
        }
      }
    }

    nav > ul {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;

      li {
        margin-right: 1rem;
        font-size: 20px;
        font-weight: bold;

        .btn-dark-mode {
          background: transparent;
          position: relative;
          top: 4px;
          padding: 0;
          margin: 0;
          height: min-content;
          width: min-content;

          svg {
            color: #fff;
            font-size: 24px;
            opacity: 0;
            animation: fadeIn 0.6s ease;
            animation-fill-mode: forwards;
            &.filled-eye {
              color: #222;
            }
          }
        }

        a {
          position: relative;
          text-decoration: none;
          &.active {
            text-decoration: underline;
          }
          color: var(--color);
        }
      }

      li:last-of-type {
        margin-right: 0;
      }
    }
    #hamburger {
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 0;

    .inner-container {
      h1 {
        a {
          .short {
            display: block;
            padding-left: 0px;
          }

          .long {
            display: none;
          }
        }
      }
      nav {
        display: none;
      }

      #hamburger {
        display: block;
      }
    }
  }
`;

function Nav() {
  const darkMode = useDarkMode(false);
  const [isShow, setIsShow] = useState(false);
  const user = useContext(UserContext);

  useEffect(() => {
    setIsShow(true);
  }, []);

  return (
    <Header>
      <div className="inner-container">
        <h1>
          <Link activeClassName="active" to="/">
            {/* <span className="long">Adult Language Learning</span> */}
            <span className="short">ALL</span>
          </Link>
        </h1>
        <nav>
          <ul>
            <li>
              <button
                className="btn-dark-mode"
                onClick={(e) => {
                  e.preventDefault();
                  darkMode.toggle();
                }}
                aria-label="Dark Mode Toggle"
              >
                {isShow &&
                  (darkMode.value ? (
                    <AiOutlineEye />
                  ) : (
                    <AiFillEye className="filled-eye" />
                  ))}
              </button>
            </li>
            {/* <li>
              <button
                className="btn-dark-mode"
                onClick={(e) => {
                  e.preventDefault();
                  console.log("click");
                }}
                aria-label="Dark Mode Toggle"
              >
                {isShow &&
                  (darkMode.value ? (
                    <TbBrandDiscord />
                  ) : (
                    <TbBrandDiscordFilled className="filled-eye" />
                  ))}
              </button>
            </li> */}
            {urls.map((url) => {
              if (url.isAdmin && !adminEmails.includes(user.email)) {
                return null;
              }
              return (
                <li key={url.href}>
                  <Link
                    partiallyActive={url.partiallyActive}
                    activeClassName="active"
                    to={url.href}
                    getProps={url.getProps}
                  >
                    {url.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
        <HamburgerMenu />
      </div>
    </Header>
  );
}

export default Nav;
