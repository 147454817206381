const React = require("react");
const Layout = require("./src/components/Layout").default;

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

// const noScrollRoutes = /(\/app\/videos\/\d+|\/app\/videos\/flashcards\/.*)/;

// exports.shouldUpdateScroll = (pluginOptions) => {
//   if (pluginOptions.pathname.match(noScrollRoutes)) {
//     return false;
//   }
//   return true;
// };
