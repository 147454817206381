import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { footerUrls } from "./urls";
import useDarkMode from "use-dark-mode";
import { AiOutlineEye, AiFillEye } from "react-icons/ai";
import adminEmails from "../util/adminEmails";
import { UserContext } from "./Layout";
// import FlagSvg from "./FlagSvg";

const Container = styled.div`
  background: #000000;

  color: var(--text);
  /* overflow: hidden;

  position: relative; */

  /* @media screen and (max-width: 1250px) {
    svg#Minnesota-flag {
      display: none;
    }
  } */
`;

const FooterElement = styled.footer`
  max-width: 500px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
  margin-top: 6rem;
  padding-top: 6rem;
  padding-bottom: 2rem;

  h3 {
    margin: 0;
    padding: 0;
    max-width: 200px;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    a {
      color: var(--text);
    }
  }

  nav > ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    li {
      font-size: 22px;
      padding: 4px;
      font-weight: normal;
      text-align: center;

      #footer-btn-dark-mode {
        background: transparent;
        position: relative;
        top: 4px;
        padding: 0;
        margin: 0;
        height: min-content;
        width: min-content;

        svg {
          color: var(--text);
          font-size: 24px;
          opacity: 0;
          animation: fadeIn 0.6s ease;
          animation-fill-mode: forwards;
        }
      }

      a {
        position: relative;
        color: var(--text);
      }
    }
  }
`;

const Line = styled.div`
  box-sizing: border-box;
  height: 30px;
  width: 100%;
  background: var(--color);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px;

  a {
    color: #fff;
    font-style: italic;
    letter-spacing: 2px;
    font-size: 18px;
  }
`;

function Footer() {
  const darkMode = useDarkMode();
  const [isShow, setIsShow] = useState(false);
  const user = useContext(UserContext);

  useEffect(() => {
    setIsShow(true);
  }, []);

  return (
    <Container>
      {/* <FlagSvg /> */}
      <FooterElement>
        <h3>
          <Link to="/">Avid Language Learning</Link>
        </h3>
        <nav>
          <ul>
            <li>
              <button
                id="footer-btn-dark-mode"
                onClick={(e) => {
                  e.preventDefault();
                  darkMode.toggle();
                }}
                aria-label="Dark Mode Toggle"
              >
                {isShow && darkMode.value ? <AiOutlineEye /> : <AiFillEye />}
              </button>
            </li>
            {footerUrls.map((url) => {
              if (url.isAdmin && !adminEmails.includes(user.email)) {
                return null;
              }
              return (
                <li key={url.href}>
                  <Link activeClassName="active" to={url.href}>
                    {url.footerText ?? url.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </FooterElement>
      {/* <Line>
        <a href="https://stevefischer.dev">By Steve Fischer</a>
      </Line> */}
    </Container>
  );
}

export default Footer;
