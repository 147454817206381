import React, { createContext, useEffect, useState } from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import styled from "styled-components";
import axios from "axios";

import { API_ROOT } from "../constants";

import "./global.css";

const Main = styled.main`
  min-height: 100vh;
  width: 100%;

  p {
    font-size: 1.35rem;
  }
`;

export const UserContext = createContext({});

export const FlashcardContext = createContext({});

function Layout({ children }) {
  const [user, setUser] = useState({ isLoading: true });
  const [flashcards, setFlashcards] = useState([]);

  useEffect(() => {
    // fetch user if there is a jwt in local storage
    const token = localStorage.getItem("token");
    function registerAnon() {
      axios.post(API_ROOT + "users/register-anon").then((res) => {
        const { token, uuid } = res.data;
        localStorage.setItem("token", token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        setUser({
          name: "",
          email: "",
          uuid,
          status: "anonymous",
          isLoading: false,
        });
      });
    }

    try {
      if (!token) {
        registerAnon();
      } else {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        axios.get(API_ROOT + "users/current_user").then((res) => {
          if (!res?.data?.currentUser) {
            return registerAnon();
          }
          setUser({
            ...res?.data?.currentUser,
            isLoading: false,
          });
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <UserContext.Provider value={{ ...user, setUser }}>
      <FlashcardContext.Provider value={{ flashcards, setFlashcards }}>
        <Nav />
        <Main>{children}</Main>
        <Footer />
      </FlashcardContext.Provider>
    </UserContext.Provider>
  );
}

export default Layout;
