const urls = [
  // {
  //   href: "/app/news",
  //   text: "News",
  //   partiallyActive: true,
  // },
  // {
  //   href: "/#guides",
  //   text: "Guide",
  //   partiallyActive: false,
  //   isAdmin: false,
  // },
  {
    href: "/app/catalog",
    text: "Catalog",
    footerText: "Catalog",
    isAdmin: false,
    partiallyActive: true,
    getProps({ location: { pathname } }) {
      return {
        className:
          pathname.includes("catalog") && !pathname.includes("flashcards")
            ? "active"
            : "",
      };
    },
  },
  {
    href: "/app/catalog/flashcards",
    text: "Flashcards",
    footerText: "Flashcards",
    isAdmin: false,
    partiallyActive: true,
    getProps({ location: { pathname } }) {
      return {
        className: pathname.includes("flashcards") ? "active" : "",
      };
    },
  },
  {
    href: "/account",
    isAdmin: false,
    text: "Account",
    partiallyActive: true,
  },
];

export const footerUrls = [
  {
    href: "/",
    text: "Home",
    partiallyActive: false,
    isAdmin: false,
  },
  ...urls,
  {
    href: "/terms-of-service",
    text: "Terms of Service",
    isAdmin: false,
    partiallyActive: true,
  },
  {
    href: "/app/catalog/add-channel",
    text: "Add Channel",
    footerText: "Add Channel",
    isAdmin: true,
    partiallyActive: true,
    getProps({ location: { pathname } }) {
      return {
        className: pathname.includes("add-channel") ? "active" : "",
      };
    },
  },
  {
    href: "/app/videos/1",
    text: "YouTube Lab",
    footerText: "YT Lab",
    isAdmin: true,
    partiallyActive: true,
    getProps({ location: { pathname } }) {
      return {
        className:
          pathname.includes("video") && !pathname.includes("flashcards")
            ? "active"
            : "",
      };
    },
  },
  {
    href: "/app/videos/flashcards",
    text: "Flashcards",
    footerText: "YT Flashcards",
    isAdmin: true,
    partiallyActive: true,
    getProps({ location: { pathname } }) {
      return {
        className: pathname.includes("flashcards") ? "active" : "",
      };
    },
  },
  {
    href: "/app/books/languages",
    text: "Books",
    footerText: "Books",
    isAdmin: true,
    partiallyActive: true,
    getProps({ location: { pathname } }) {
      return {
        className: pathname.includes("books") ? "active" : "",
      };
    },
  },
];

export default urls;
